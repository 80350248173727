import React from "react"
import styled from "styled-components"
import FaqItem from "./FaqItem"
import { H1GrapePurple, medWrapper } from "../../styles/helpers"

const FaqsList = ({ data }) => {
  console.log("data", data)
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h1>{data.faqsTitle}</h1>
        </div>
        <div className="faqsContainer">
          {data.faqsItems.map((faq, index) => {
            return (
              <FaqItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            )
          })}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.div`
  position: relative;
  padding-bottom: 2.5rem;

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h1 {
      ${H1GrapePurple};
      font-weight: 600;
    }
  }

  .faqsContainer {
    width: 100%;
    padding: 1.5rem 0;

    @media (min-width: 768px) {
      width: calc(100% - 16rem);
    }
  }
`

export default FaqsList
