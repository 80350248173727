import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import FaqsList from "../components/faqs/FaqsList"

const Faqs = props => {
  const { seoInfo } = props.data
  const faqsList = props?.data?.faqsList?.template?.faqs
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <FaqsList data={faqsList} />
    </Layout>
  )
}

export const faqsQuery = graphql`
  query faqsTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    faqsList: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_FAQs {
          faqs {
            faqsTitle
            faqsItems {
              answer
              question
            }
          }
        }
      }
    }
  }
`
export default Faqs
